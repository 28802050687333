<template>
  <div class="c-app" style="background-color: #ffffff" v-if="uid !== null">
    <div style="z-index: 9999;" v-if="loading === false">
      <TheSidebar />
    </div>
    <div class="c-wrapper">
      <div v-if="loading === false">
        <TheMenuHeader v-if="$route.name !== 'DineInHome'" />
      </div>
      <main class="c-main">
        <div class="c-body">
          <CContainer>
            <transition name="fade">
              <div v-if="loading === true">
                <div class="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div v-else-if="loading === false">
                <div class="text-center" v-if="orderCompleted === true" style="margin-top: 150px">
                  <img src="https://cdn-icons-png.flaticon.com/512/4594/4594686.png" class="img-fluid" width="50%"
                    alt="" />
                  <h4 class="mt-5 font-weight-bold">{{ $t('endOfTransection') }}</h4>
                  <span class="text-description">{{ $t('endOfTransectionDialog') }}</span>
                </div>
                <div v-else>
                  <router-view></router-view>
                </div>
              </div>
            </transition>
          </CContainer>
        </div>
      </main>
    </div>
  </div>
  <div v-else style="background-color: #ffffff" class="min-vh-100">
    <div class="text-center p-5">
      <img style="margin-top: 200px" src="/img/staff_assist.png" class="img-fluid" width="60%" alt="" />
      <h5 class="mt-5 font-weight-bold">
        ติดต่อพนักงานของเรา<br>เพื่อขอความช่วยเหลือ
      </h5>
      <span class="text-muted">Please contact our staff for assistance</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheSidebar from './TheSidebarOrder'
import TheMenuHeader from '../views/menu/NavBar'
import poscrm from '@/services/poscrm'
import '@/util/loader.css'

export default {
  components: {
    TheMenuHeader,
    TheSidebar,
  },
  data() {
    return {
      loading: true,
      orderCompleted: false,
    }
  },
  computed: {
    ...mapGetters(['shopLineOA']),
    uid() {

      return `${localStorage.getItem('uid')}` || 'null'
    },
    shopObjectId() {
      return `${localStorage.getItem('shopObjectId')}`
    },
    tableObjectId() {
      if (this.table) {
        return this.table.objectId
      } else {
        return ''
      }
    },
  },
  beforeCreate() {
    this.$store.dispatch('getShopLineOA')
    localStorage.setItem('mode', process.env.NODE_ENV)
    if (this.$route.name == 'DineInHome') {
      let uid = this.$route.query.uid
      localStorage.setItem('uid', uid)
    }
  },
  created() {
    this.getOrderShopSetting()
  },
  methods: {
    redirect() {
      if (this.shopLineOA.length === 0) {
        this.loading = true
      } else if (this.shopLineOA.length > 0) {
        this.loading = false
      }
    },
    async getOrderShopSetting() {
      const uid = `${localStorage.getItem('uid')}`
      const shopObjectId = `${localStorage.getItem('shopObjectId')}`

      let params = {
        shopObjectId: shopObjectId,
      }
      try {
        await poscrm({
          method: 'get',
          url: '/api/v2.1/' + uid + '/OrderShopSetting',
          params: params,
        }).then((res) => {
          let themeColor = res.data?.data[0]?.themeColor || '#29B46B'
          if (themeColor) {
            $(':root').css('--bg-color-theme', themeColor)
          } else {
            $(':root').css('--bg-color-theme', '#29B46B')
          }
        })
      } catch (error) {
        $(':root').css('--bg-color-theme', '#29B46B')
        throw new Error(error)
      }
    },
    getReceiptTransaction() {
      let receiptObjectId = localStorage.getItem('receiptObjectId') || null

      if (receiptObjectId != null) {
        poscrm
          .get('/api/v1.1/' + this.uid + '/Receipt/data/' + receiptObjectId)
          .then((res) => {

            let document = {}
            document = res?.data?.data?.document || {}

            if (document != null && document) {
              if (document.receiptStatus === 'A') {
                this.orderCompleted = true
                localStorage.clear()
                sessionStorage.clear()
              }
            } else {
              localStorage.clear()
              sessionStorage.clear()
              this.$router.push('/texp?errorCode=-2001')
            }
          })
      } else {
        let uid = localStorage.getItem('uid')
        if (uid == null) {
          this.orderCompleted = true
        }
      }
    },
  },
  watch: {
    shopLineOA() {
      this.redirect()
    },
    $route() {
      this.getReceiptTransaction()
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
